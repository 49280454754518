export const initSetEmailLocalstorage = () => {
    const forms = document.querySelectorAll('[data-localstorage-form-email]');
    if (!forms.length) return;

    [...forms].forEach((form) => {
        form.addEventListener(
            'submit',
            function () {
                const input = this.querySelector('[data-localstorage-email]');
                localStorage.setItem('user-email', input.value.trim());
            },
            false,
        );
    });
};
